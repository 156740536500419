.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex: 1;
  text-align: center;
  background-color: var(--background-color);
}

.pageContent {
  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: var(--background-color);
  width: 100%;
  max-width: 72ch;

  box-shadow: 0 0 100px rgba(var(--foreground-color-rgb), 0.1);
}

.body {
  flex: 1;
  padding: 16px;
}
