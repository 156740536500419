.container {
  display: flex;
  flex-direction: row;
  height: 60px;
  background-color: var(--header-color);
  margin: 0;
  padding: 0;
  line-height: 100%;
  overflow: hidden;

  color: #000;
}

.container h1 {
  flex: 1;
}

.container h1 a {
  color: #000;
}

.container .button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.5);
  transition: 0.25s all;
}

.button:hover,
.button:focus {
  color: #000;
}

.container .button:last-child {
  border-left: 2px solid rgba(0, 0, 0, 0.25);
}
.container .button:last-child:hover {
  border-left: 2px solid #000;
}

.container .button:first-child {
  border-right: 2px solid rgba(0, 0, 0, 0.25);
}
.container .button:first-child:hover {
  border-right: 2px solid #000;
}
