body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --header-color: #5dfbd7;
  --header-color-rgb: 93, 251, 215;

  --link-color: #3e4af2;
  --link-color-rgb: 62, 74, 242;

  --frame-color: #484554;
  --frame-color-rgb: 72, 69, 84;

  --subtle-accent-color: #ada9bb;
  --subtle-accent-color-rgb: 173, 169, 187;

  --error-color: #dc0015;
  --error-color-rgb: 220, 0, 21;

  --highlight-color: #ff2941;
  --highlight-color-rgb: 255, 41, 65;

  --background-color: #fff;
  --background-color-rgb: 255, 255, 255;

  --foreground-color: #000;
  --foreground-color-rgb: 0, 0, 0;
  --foreground-color-subtle: #0007;

  color: var(--foreground-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --header-color: #00c2a0;
    --header-color-rgb: 0, 194, 160;

    --link-color: #3e71f2;
    --link-color-rgb: 62, 113, 242;

    --frame-color: #484554;
    --frame-color-rgb: 72, 69, 84;

    --subtle-accent-color: #ada9bb;
    --subtle-accent-color-rgb: 173, 169, 187;

    --error-color: #dc0015;
    --error-color-rgb: 220, 0, 21;

    --highlight-color: #ff2941;
    --highlight-color-rgb: 255, 41, 65;

    --background-color: #000;
    --background-color-rgb: 0, 0, 0;

    --foreground-color: #fff;
    --foreground-color-rgb: 255, 255, 255;
    --foreground-color-subtle: #fff7;
  }
}

a,
a:visited,
a:active {
  color: var(--link-color);
  text-decoration: none;
  transition: all 0.25s;
}

a:hover,
a:focus {
  color: var(--highlight-color);
  text-decoration: underline;
}

button {
  font-size: 1em;
  color: var(--foreground-color-subtle);
  border: var(--foreground-color-subtle) 1px solid;
  background-color: transparent;
  margin: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  transition: all 0.25s;
}

button:disabled,
button:disabled:hover,
button:disabled:focus,
button:disabled:active {
  color: var(--foreground-color-subtle);
  border: var(--foreground-color-subtle) 1px solid;
  cursor: not-allowed;
}

button:hover,
button:focus {
  color: var(--foreground-color);
  border-color: var(--foreground-color);
}
