.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #000a;
  backdrop-filter: blur(4px);

  z-index: 999;
}

.modal {
  border-radius: 8px;
  border: 2px solid var(--highlight-color);
  color: var(--foreground-color);
  padding: 16px;
  background-color: var(--background-color);
  max-width: 50vw;
}

@media (max-width: 550px) {
  .modal {
    max-width: calc(100vw - 8px);
  }
}

.title {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(var(--highlight-color-rgb), 0.5);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
}

.buttons button {
  font-size: 1em;
  color: var(--foreground-color);
  border: rgba(var(--highlight-color-rgb), 0.25) 1px solid;
  background-color: rgba(var(--highlight-color-rgb), 0.25);
}

.buttons button:hover {
  background-color: rgba(var(--highlight-color-rgb), 1);
}
