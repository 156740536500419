.container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  text-align: start;
}

.container ul li div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.container button {
  flex-shrink: 0;
}

.question {
  padding-bottom: 8px;
}

.answered a::before {
  content: "✅";
  margin-right: 4px;
}

.pending a::before {
  content: "⏳";
  margin-right: 4px;
}
