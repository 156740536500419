.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.version {
  width: 6ch;
  font-family: "Roboto Mono", "Courier New", Courier, monospace;
  margin: 0 8px;
  cursor: help;
  transition: all 0.2s;
  border-radius: 4px;
  padding: 4px;
}

.version:hover {
  color: unset !important;
  background-color: unset !important;
}
