.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  transition: all 0.25s;
}

.toast {
  background-color: rgba(var(--header-color-rgb), 0.5);
  border: 2px solid rgba(var(--header-color-rgb), 1);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.75);

  font-weight: bold;
}
